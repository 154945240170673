<template>
	<v-container>
		<newton-calendar></newton-calendar>
	</v-container>
</template>
<script>
import NewtonCalendar from "@/components/main/NewtonCalendar";
export default {
	components: { NewtonCalendar },
};
</script>

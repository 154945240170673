<template>
	<v-card>
		<v-card-actions>
			<h3 class="box-title text-center">Calendario de Eventos</h3>
			<v-spacer></v-spacer>
			<v-menu>
				<template v-slot:activator="{ on }">
					<v-btn text icon v-on="on">
						<v-icon>filter_list</v-icon>
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item v-if="api.modulos.tickets">
						<v-list-item-content>
							{{ "tickets" | trans }}
						</v-list-item-content>
						<v-list-item-action>
							<v-checkbox @change="prepareEvents()" color="primary" v-model="show.tickets"></v-checkbox>
						</v-list-item-action>
					</v-list-item>
					<v-list-item v-if="api.modulos.pedidos">
						<v-list-item-content>
							{{ "orders" | trans }}
						</v-list-item-content>
						<v-list-item-action>
							<v-checkbox @change="prepareEvents()" color="primary" v-model="show.pedidos"></v-checkbox>
						</v-list-item-action>
					</v-list-item>
					<v-list-item v-if="api.modulos.facturas">
						<v-list-item-content>
							{{ "invoices" | trans }}
						</v-list-item-content>
						<v-list-item-action>
							<v-checkbox @change="prepareEvents()" color="primary" v-model="show.facturas"></v-checkbox>
						</v-list-item-action>
					</v-list-item>
					<v-list-item v-if="api.modulos.alertas">
						<v-list-item-content>
							{{ "alerts" | trans }}
						</v-list-item-content>
						<v-list-item-action>
							<v-checkbox @change="prepareEvents()" color="primary" v-model="show.alertas"></v-checkbox>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-actions>
		<v-card-text>
			<v-layout row wrap>
				<v-flex xs12>
					<FullCalendar
						@eventClick="EventSelected"
						ref="calendar"
						:options="options"
						@datesRender="viewRender"
						@eventRender="eventRender"
					></FullCalendar>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-dialog v-model="dialog" scrollable max-width="750px">
			<crud-viewer :item="item" :headers="headers" :cancel-action="() => (dialog = false)" v-if="item">
				<template v-slot:title="">
					<div>
						<span class="text-capitalize"> {{ type | trans }}: </span>
						<span>{{ item.full_name || item.numero_pedido || item.number }} </span>
					</div>
				</template>
			</crud-viewer>
		</v-dialog>
	</v-card>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import esLocale from "@fullcalendar/core/locales/es";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";

import CrudViewer from "@/components/main/CrudViewer";
import models from "@/models/modelsDefinitions";

export default {
	components: {
		FullCalendar,
		CrudViewer,
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			var date = moment(this.$refs.calendar.getApi().getDate());
			var key = date.clone().startOf("month").format("YYYY-MM-DD");
			if (!this.list[key]) {
				this.list[key] = {
					alertas: [],
					pedidos: [],
					tickets: [],
				};
				var promises = [];

				if (this.api.modulos.tickets) {
					var promiseTickets = this.api.get(
						`tickets?orWhereDateBetween[created_at]=${date.startOf("month").format("YYYY-MM-DD")},
						${date.endOf("month").format("YYYY-MM-DD")}&orWhereDateBetween[vencimiento]=${date
							.startOf("month")
							.format("YYYY-MM-DD")},
						${date.endOf("month").format("YYYY-MM-DD")}&with[]=guardian&limit=150`
					);
					promises.push(promiseTickets);
					promiseTickets
						.then((resp) => {
							this.list[key].tickets = resp.data
								.map((t) => {
									return {
										id: t.id,
										title:
											this.$options.filters.trans("literals.ticket") +
											" " +
											t.titulo +
											" | " +
											(t.guardian ? t.guardian.full_name : ""),
										estado: t.estado,
										type: "ticket",
										header: this.$options.filters.trans("literals.ticket") + " " + t.titulo,
										start: t.vencimiento ? moment(t.vencimiento) : moment(t.created_at).utc(),
										description: t.contenido,
										className: `ticket ${t.estado}`,
										// borderColor: "cyan"
									};
								})
								.filter((p) => p.start);
						})
						.catch(this.error);
				}
				if (this.api.modulos.pedidos) {
					var promiseOrders = this.api.get(
						`pedidos?whereDateBetween[fecha_pedido]=${date.startOf("month").format("YYYY-MM-DD")},
						${date.endOf("month").format("YYYY-MM-DD")}&limit=150`
					);
					promises.push(promiseOrders);
					promiseOrders
						.then((resp) => {
							this.list[key].pedidos = resp.data
								.map((p) => {
									return {
										id: p.id,
										title: this.$options.filters.trans("literals.order") + " " + p.numero_pedido,
										estado: p.estado,
										type: "pedido",
										header: this.$options.filters.trans("literals.order") + " " + p.numero_pedido,
										start: moment(p.fecha_pedido).utc().toDate(),
										description: "" + p.total,
										className: `order ${p.estado}`,
										// borderColor: "magenta"
									};
								})
								.filter((p) => p.start);
						})
						.catch(this.error);
				}
				if (this.api.modulos.facturas) {
					var promiseInvoices = this.api.get(
						`invoices?whereDateBetween[fecha]=${date.startOf("month").format("YYYY-MM-DD")},
						${date.endOf("month").format("YYYY-MM-DD")}&limit=450`
					);
					promises.push(promiseInvoices);
					promiseInvoices
						.then((resp) => {
							this.list[key].facturas = resp.data
								.map((p) => {
									return {
										id: p.id,
										title: this.$options.filters.trans("literals.invoices") + " " + p.number,
										estado: p.estado,
										type: "invoice",
										header: this.$options.filters.trans("literals.invoice") + " " + p.number,
										start: moment(p.fecha).utc().toDate(),
										description: "" + p.total,
										className: `invoice ${p.estado}`,
									};
								})
								.filter((p) => p.start);
						})
						.catch(this.error);
				}
				if (this.api.modulos.alertas) {
					var promiseAlertas = this.api.get(
						`alertas?where[user_id]=${window.user_id}&whereDateBetween[programado]=${date
							.startOf("month")
							.format("YYYY-MM-DD")},
						${date.endOf("month").format("YYYY-MM-DD")}&limit=150`
					);
					promises.push(promiseAlertas);
					promiseAlertas
						.then((resp) => {
							this.list[key].alertas = resp.data
								.map((p) => {
									return {
										id: p.id,
										title: this.$options.filters.trans("literals.alert") + " " + p.titulo,
										header: this.$options.filters.trans("literals.alert") + " " + p.titulo,
										type: "alerta",
										start: moment(p.programado).utc().toDate(),
										description: p.mensaje,
										className: "alert",
										// borderColor: "yellow"
									};
								})
								.filter((p) => p.start);
						})
						.catch(this.error);
				}
				if (this.api.modulos.anotaciones) {
					var promiseAnnotations = this.api.get(
						`annotations?select=DATE_FORMAT(created_at,'%Y-%m-%d') as lapse,user_id,count(*)%20as%20count&groupDate[day]=created_at&with[]=user&whereDategte[created_at]=${date
							.startOf("month")
							.format("YYYY-MM-DD")}&whereDatelwe[created_at]=${date.endOf("month").format("YYYY-MM-DD")}`
					);
					promises.push(promiseAnnotations);
					promiseAnnotations
						.then((resp) => {
							this.list[key].anotaciones = resp.data
								.map((p) => {
									return {
										id: p.id,
										title:
											this.$options.filters.trans("literals.annotation") +
											this.$options.filters.trans("literals.quantity") +
											": " +
											p.count,
										header:
											this.$options.filters.trans("literals.quantity") +
											" " +
											this.$options.filters.trans("literals.annotation"),
										type: "annotation",
										start: moment(p.lapse).utc().toDate(),
										backgroundColor: "orange",
										borderColor: "yellow",
										allDay: true,
										className: "annotation",
									};
								})
								.filter((p) => p.start);
						})
						.catch(this.error);
				}

				Promise.all(promises).then(() => this.prepareEvents(date));
			} else {
				this.prepareEvents(date);
			}
		},
		prepareEvents(date) {
			if (!date) {
				date = moment(this.$refs.calendar.getApi().getDate());
			}
			this.date = date;
			var events = [];
			var list = this.list[date.clone().startOf("month").format("YYYY-MM-DD")];
			if (this.show.tickets && this.api.modulos.tickets) {
				events = events.concat(list.tickets);
			}
			if (this.show.pedidos && this.api.modulos.pedidos) {
				events = events.concat(list.pedidos);
			}
			if (this.show.facturas && this.api.modulos.facturas) {
				events = events.concat(list.facturas);
			}
			if (this.show.alertas && this.api.modulos.alertas) {
				events = events.concat(list.alertas);
			}
			if (this.show.anotaciones && this.api.modulos.anotaciones) {
				events = events.concat(list.anotaciones);
			}
			let newView = this.$refs.calendar.getApi().currentViewType;
			if (this.options.events.length != events.filter((e) => e).length || newView != this.oldView) {
				this.$set(
					this.options,
					"events",
					events
						.filter((e) => e)
						.map((e) => {
							e.start = moment(e.start).toDate();
							return e;
						})
				);
			}
			this.oldView = newView;
		},
		eventRender(ev) {
			var event = ev.event;
			var element = ev.el;
			element.setAttribute("data-toggle", "tooltip");
			element.setAttribute("data-placement", "top");
			element.setAttribute("data-html", "true");
			var desc = "";
			if (event.extendedProps.description) {
				desc = event.extendedProps.description.replace(/<[^>]+>/g, "");
			}
			element.setAttribute(
				"title",
				`${event.extendedProps.header || event.title}
        ${event.extendedProps.estado ? `Estado: ${event.extendedProps.estado}` : ""}
        ${desc}
        `
			);
		},
		EventSelected(ev) {
			var event = ev.event;
			this.type = event.extendedProps.type;
			switch (event.extendedProps.type) {
				case "ticket":
					this.api.get(`tickets/${event.id}?include=user,guardian,cliente`).then((resp) => {
						this.item = resp.data;
						this.dialog = true;
						this.headers = models.tickets.headers;
					});
					break;
				case "pedido":
					this.api.get(`pedidos/${event.id}?include=user,cliente,vendedor,entidad,invoice`).then((resp) => {
						this.item = resp.data;
						this.dialog = true;
						this.headers = models.pedidos.headers;
					});
					break;
				case "invoice":
					this.api.get(`invoices/${event.id}?include=user,cliente,vendedor,entidad`).then((resp) => {
						this.item = resp.data;
						this.dialog = true;
						this.headers = models.invoices.headers;
					});
					break;
				case "alerta":
					console.log(event);
					break;
				default:
					console.log(event);
					break;
			}
		},
		viewRender() {
			this.getData();
		},
	},
	data() {
		return {
			oldView: null,
			date: null,
			dialog: false,
			item: null,
			type: null,
			headers: [],
			show: {
				alertas: true,
				tickets: true,
				pedidos: true,
				facturas: true,
				annotations: false,
			},
			list: {},
			options: {
				dayMaxEventRows: true,
				buttonIcons: true,
				navLinks: false,
				initialView: "dayGridMonth",
				plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, momentPlugin],
				events: [],
				locale: esLocale,
				editable: false,
				headerToolbar: {
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
				},
				eventClick: this.EventSelected,
				datesSet: this.viewRender,
				viewDidMount: this.viewRender,
			},
		};
	},
};
</script>

<style>
/* Material design button */
.fc-button {
	color: black;
	background-color: white;
	display: inline-block;
	position: relative;
	cursor: pointer;
	min-height: 24px;
	min-width: 44px;
	border-radius: 25px;
	margin: 0;
	border: 0;
	padding: 0 6px;
	letter-spacing: 0.01em;
	white-space: nowrap;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
	font-style: inherit;
	font-variant: inherit;
	font-family: inherit;
	text-decoration: none;
	overflow: hidden;
	-webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
		background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.fc-button:hover {
	background-color: #488aff;
}
.fc-button:focus,
.fc-button:hover,
.fc-button-active {
	text-decoration: none !important;
	background-color: #488aff !important;
}
.fc-button:hover,
.fc-event {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.fc-toolbar h2 {
	font-size: 16px;
	margin: 0 4px;
}
</style>
<style>
.fc-event.order {
	background-color: #488aff !important;
	color: #fff;
}
.fc-event.invoice {
	background-color: #4caf50 !important;
	color: #fff;
}
.fc-event.ticket {
	background-color: #f0ad4e !important;
	color: #fff;
}

.abierto {
	border: 2px solid #488aff !important;
}
.completado {
	border: 2px solid #5cb85c !important;
}
.vencido {
	border: 2px solid #d9534f !important;
}
.en.curso,
.pedido {
	border: 2px solid #f0ad4e !important;
}
.pagado {
	border: 2px solid #5cb85c !important;
}
.enviado {
	border: 2px solid #39cccc !important;
}
.entregado {
	border: 2px solid #5cb85c !important;
}
.emitida {
	border: 2px solid #488aff !important;
}
.pagada {
	border: 2px solid #5cb85c !important;
}
.anulada,
.rechazada,
.vencido,
.vencida {
	border: 2px solid #d9534f !important;
}
</style>
